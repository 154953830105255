






















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Global, Reports } from '@/store';
import { cloneDeep } from 'lodash';

@Component({
  components: {}
})
export default class TondoReports extends Vue {
  @Global.State('lang') lang;
  @Reports.Getter('commissionedFixtures') commissionedFixtures;
  @Reports.Getter('commissionedCabinets') commissionedCabinets;

  tondoReports = {
    // 'reports/installations': {
    //   title: 'Installations',
    //   text: '',
    //   src: require('@/assets/images/reports/installed.png'),
    //   color: '#B0BEC5'
    // },
    'reports/inventory_management': {
      title: 'Inventory Management',
      text: '',
      src: require('@/assets/images/reports/inventory.png'),
      color: '#B2DFDB'
    },
    'reports/activity_log': {
      title: 'Activity Log',
      text: '',
      src: require('@/assets/images/reports/runtime.png'),
      color: '#FFC4C4'
    },
    'reports/power_consumption_by_device': {
      title: 'Controller Power Consumption',
      text: '',
      src: require('@/assets/images/reports/controller_power_consumption.png'),
      color: '#F0F4C3'
    },
    'reports/monitoring_report': {
      title: 'Installation Monitoring',
      text: '',
      src: require('@/assets/images/reports/installed.png'),
      color: '#C6EBC5'
    }
  };
  cabinetReports = {
    'reports/power_consumption_by_satec': {
      title: 'Cabinet Power Consumption',
      text: '',
      src: require('@/assets/images/reports/power_consumption.png'),
      color: '#B0BEC5'
    }
  };

  loading = true;
  disabledReports = [];
  final_reports = {};

  mounted() {
    this.loading = true;
    if (this.commissionedFixtures.length){
      this.final_reports = cloneDeep(this.tondoReports);
    }
    if (this.commissionedCabinets.length) {
      this.final_reports = {...this.final_reports, ...cloneDeep(this.cabinetReports)};
    }
    this.loading = false;
  }
}
